<script>
import NewsCard from "@/views/NewsCenter/components/NewsCard.vue";
import { NEWS_CENTER_TYPE } from "@/utils/enum";

export default {
  name: "IndustryDynamics",
  components: { NewsCard },
  computed: {
    NEWS_CENTER_TYPE() {
      return NEWS_CENTER_TYPE;
    }
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<template>
  <div class="industry_dynamics">
    <NewsCard :type="NEWS_CENTER_TYPE.industryDynamics" />
  </div>
</template>

<style scoped lang="scss">
.industry_dynamics {
  margin: 30px 0;
}
</style>
