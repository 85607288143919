<script>
import NewsCard from "@/views/NewsCenter/components/NewsCard.vue";
import { NEWS_CENTER_TYPE } from "@/utils/enum";

export default {
  name: "MediaReport",
  components: { NewsCard },
  computed: {
    NEWS_CENTER_TYPE() {
      return NEWS_CENTER_TYPE;
    }
  },
  data() {
    return {};
  }
};
</script>

<template>
  <div class="media_report_warp">
    <NewsCard :type="NEWS_CENTER_TYPE.mediaReport" />
  </div>
</template>

<style scoped lang="scss">
.media_report_warp {
  margin: 30px 0;
}
</style>
