<script>
import NewsCard from "@/views/NewsCenter/components/NewsCard.vue";
import { NEWS_CENTER_TYPE } from "@/utils/enum";

export default {
  name: "PublicAnnouncement",
  components: { NewsCard },
  computed: {
    NEWS_CENTER_TYPE() {
      return NEWS_CENTER_TYPE;
    }
  },
  data() {
    return {
      itemListOther: [],
      last_page: 0, // 最后一页
      current_page: 0 // 当前页
    };
  },
  mounted() {},
  methods: {}
};
</script>

<template>
  <div class="public">
    <NewsCard :type="NEWS_CENTER_TYPE.announcement" />
  </div>
</template>

<style scoped lang="scss">
.public {
  margin: 30px 0;
}
</style>
