<script>
import ViewDetailsImage from "@/views/components/ViewDetailsImage.vue";
import Not_data from "@/views/components/Not_data.vue";
import { NEWS_CENTER_TYPE } from "@/utils/enum";
import { getNewsList } from "@/api/newsCenter";
import moment from "moment";
import LearnMore from "@/views/components/LearnMore.vue";

export default {
  name: "NewsCard",
  components: { LearnMore, Not_data, ViewDetailsImage },
  props: {
    type: NEWS_CENTER_TYPE,
    getListFun: Function
  },
  watch: {},
  data() {
    return {
      itemList: [],
      last_page: 0, // 最后一页
      current_page: 0, // 当前页
      loading: false
    };
  },
  mounted() {},
  methods: {
    /**
     * 查询新闻列表
     */
    getNewsList() {
      if (this.current_page === this.last_page && this.current_page !== 0) {
        return;
      }
      this.loading = true;
      const params = {
        type: this.type,
        page: this.current_page + 1,
        limit: 10
      };
      getNewsList(params)
        .then(resp => {
          const data = resp.data;
          const itemData = data.data;
          const itemList = [];
          if (itemData && itemData.length !== 0) {
            itemData.map(item => {
              itemList.push({
                ...item,
                time: moment(item.created_at).format("YYYY-MM"),
                timeHour: moment(item.created_at).format("DD")
              });
            });
          }

          // 新闻这一栏单独处理
          if (this.type === NEWS_CENTER_TYPE.news) {
            // 第一页
            if (data.current_page === 1) {
              this.$emit("getListFun", itemList.slice(0, 3));
              this.itemList = itemList.slice(3);
            } else {
              // 其他的翻滚页
              this.itemList = this.itemListOther.concat(itemList);
            }
          } else {
            this.itemList = this.itemList.concat(itemList);
          }

          this.last_page = data.last_page;
          this.current_page = data.current_page;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getInfo(id) {
      if (this.$parent.jumpInfo) {
        this.$parent.jumpInfo(id);
      }
    }
  }
};
</script>

<template>
  <div>
    <el-skeleton :rows="6" animated :loading="loading">
      <ul
        class="infinite-list"
        v-infinite-scroll="getNewsList"
        style="overflow:auto;padding: 0;"
      >
        <template v-if="itemList.length !== 0">
          <li
            v-for="item in itemList"
            :key="item.key"
            class="infinite-list-item card_item"
            @click="getInfo(item.id)"
          >
            <div class="time_hour">
              <div class="hour">{{ item.timeHour }}</div>
              <div>{{ item.time }}</div>
            </div>
            <div class="info">
              <div class="title">{{ item.title }}</div>
              <div class="desc" v-html="item.sub_title || item.content"></div>
              <div class="save_info">
                <ViewDetailsImage />
              </div>
            </div>
            <div>
              <el-image :src="item.default_img" fit="cover" lazy />
            </div>
          </li>
        </template>
        <Not_data v-else />
      </ul>
      <LearnMore v-if="this.last_page !== this.current_page" />
    </el-skeleton>
  </div>
</template>

<style scoped lang="scss">
.card_item {
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin-bottom: 30px;
  cursor: pointer;
  .el-image {
    height: 236px;
    width: 600px;
  }
}
.info {
  width: 40%;
  .title {
    color: #000000;
  }
  .desc {
    color: rgba(0, 0, 0, 0.5);
    margin-top: 10px;
    font-size: 0.8rem;
    line-height: 22px;
    display: -webkit-box;
    word-break: break-all;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .save_info {
    margin-top: 20%;
    float: right;
  }
}
.time_hour {
  color: rgba(0, 0, 0, 0.25);
}
.hour {
  font-size: 1.6rem;
}
</style>
