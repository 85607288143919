<script>
import Banner from "@/views/components/Banner.vue";
import notData from "@/views/components/Not_data.vue";
import LeftMenu from "@/views/components/LeftMenu.vue";
import Breadcrumb from "@/views/components/Breadcrumb.vue";
import { tabsOption } from "@/views/NewsCenter/moke";
import CorporateCulture from "./CorporateCulture.vue";
import OrganizationalStructure from "./OrganizationalStructure.vue";
import GroupNews from "./GroupNews.vue";
import GroupNewsInfo from "./GroupNewsInfo.vue";
import PublicAnnouncement from "./PublicAnnouncement.vue";
import IndustryDynamics from "./IndustryDynamics.vue";
import MediaReport from "./MediaReport.vue";
import Aptitude from "./Aptitude.vue";
import VideoData from "./VideoData.vue";
import DocumentData from "./DocumentData.vue";
import { getParam } from "@/utils/utils";

export default {
  name: "index",
  components: {
    notData,
    LeftMenu,
    Breadcrumb,
    Banner,
    CorporateCulture,
    OrganizationalStructure,
    Aptitude,
    GroupNews,
    GroupNewsInfo,
    PublicAnnouncement,
    IndustryDynamics,
    MediaReport,
    VideoData,
    DocumentData
  },
  data() {
    return {
      tabsOption: tabsOption,
      currentTab: {}, // 当前选中的标签页数据
      breadcrumbOption: [], // 面包屑
      childSelect: {}, // 页面选中的节点
      showData: {} // 要显示得数据对象
    };
  },
  mounted() {
    const typeParam = getParam("type");

    let index = 0;
    this.tabsOption.map((item, i) => {
      if (item.key === typeParam) {
        index = i;
      }
    });
    this.currentTab = this.tabsOption[index];
    this.childSelect = this.tabsOption[index];
    this.checkTab(this.currentTab.key, false);
  },
  methods: {
    /**
     * 切换一级目录
     * @param key 选中的标签页
     * @param isRefresh 是否刷新
     */
    checkTab(key, isRefresh = true) {
      this.childSelect = {};
      this.showData = {};
      // 初始化三级目录
      const tabsOption = this.tabsOption;
      tabsOption.map(item => {
        if (item.key === key) {
          this.currentTab = item;
          // 添加面包屑
          this.generateBreadcrumb(item.key);

          // 还有下一级菜单
          if (item.menuChild && item.menuChild.length !== 0) {
            // 设置第一次选中
            this.checkChildSelect(item);
          } else {
            const idParam = getParam("id");
            if (idParam && idParam !== 0) {
              this.checkNewsInfo(idParam);
            } else {
              // 只有当前这级菜单
              this.childSelect = item;
              this.showData = item;
            }

            // 地址栏是否需要变更
            if (isRefresh) {
              this.$router.replace({
                path: "/newsCenter",
                query: {
                  type: key,
                  date: new Date().getTime()
                }
              });
            }
          }
        }
      });
    },
    /**
     * 切换二级，三级目录保存数据
     * @param item
     */
    checkChildSelect(item) {
      let flag = true;
      let defaultTab = {};
      const typeKey = item.key;
      const tabParam = getParam("tabKey");
      // 没有三级目录，三级目录不需要选中当前的菜单节点
      if (item.menuChild && item.menuChild.length !== 0) {
        let length = 0;
        if (tabParam) {
          const menuChild = item.menuChild;
          menuChild.map((m, i) => {
            if (m.key === tabParam) {
              length = i;
              defaultTab = m;
            }
          });
        }
        defaultTab = item.menuChild[length];
      } else {
        defaultTab = item;
      }
      this.childSelect = defaultTab;
      this.showData = defaultTab;
      // 添加面包屑
      this.generateBreadcrumb(defaultTab.key);

      if (flag) {
        flag = false;
        this.$router.push({
          path: "/newsCenter",
          query: {
            type: typeKey,
            tabKey: defaultTab.key
          }
        });
      }
    },

    /**
     * 切换到新闻详情
     * @param id 新闻编号
     */
    checkNewsInfo(id) {
      this.generateBreadcrumb("newsInfo", id);
      const item = {
        key: "newsInfo",
        id
      };
      this.showData = item;
    },

    /**
     * 生成面包屑
     * @param key 新生成的目录
     * @param id 进info的编号
     */
    generateBreadcrumb(key, id = 0) {
      const typeParam = getParam("type");
      const tabParam = getParam("tabKey");
      const breadcrumbOption = this.breadcrumbOption;
      // 从新拼装好的面包屑
      const currentOption = breadcrumbOption;
      let path = "/newsCenter";
      if (typeParam) {
        path += "?type=" + typeParam;
      }
      if (tabParam) {
        path += "&tabKey=" + tabParam;
      }
      if (id && id !== 0) {
        path += "&id=" + id;
      }
      // 跟节点
      if (!breadcrumbOption || breadcrumbOption.length === 0) {
        currentOption.push({
          key: "newsCenter",
          toPath: path
        });
      }
      if (key) {
        // currentOption.splice(menuCount, menuCount + 1, newOption);
        // 判断是否存在下一级
        const isFlog = currentOption.some(o => o.key === key);
        if (!isFlog) {
          currentOption.push({
            key,
            toPath: path
          });
        }
      }
      this.breadcrumbOption = currentOption;
      if (id && id !== 0) {
        this.$router.push({
          path
        });
      }
    }
  }
};
</script>

<template>
  <div>
    <Banner
      :bannerSrc="require('@/assets/banner/newsCenter.jpg')"
      :tabs="tabsOption"
      :selTab="currentTab.key"
    />
    <div class="padding-comm-warp _content_warp news">
      <template
        v-if="
          [
            'corporateCulture',
            'organizationalStructure',
            'aptitude',
            'intro'
          ].includes(showData.key)
        "
      >
        <div class="left">
          <LeftMenu :currentTab="currentTab" :childSelect="childSelect" />
        </div>
        <div class="right">
          <Breadcrumb :breadcrumbList="breadcrumbOption" />
          <div class="content">
            <!--            企业文化-->
            <div v-if="showData.key === 'corporateCulture'">
              <CorporateCulture />
            </div>
            <!--            组织架构-->
            <div v-else-if="showData.key === 'organizationalStructure'">
              <OrganizationalStructure />
            </div>
            <!--            荣誉资质-->
            <div v-else-if="showData.key === 'aptitude'">
              <Aptitude />
            </div>
            <div v-else>
              <notData />
            </div>
          </div>
        </div>
      </template>
      <div class="other_news" v-else>
        <Breadcrumb :breadcrumbList="breadcrumbOption" />
        <div class="content">
          <!--            新闻中心-->
          <template v-if="showData.key === 'news'">
            <GroupNews />
          </template>
          <!--            新闻中心-->
          <template v-if="showData.key === 'newsInfo'">
            <GroupNewsInfo :id="showData.id" />
          </template>
          <!--            公示公告-->
          <template v-else-if="showData.key === 'announcement'">
            <PublicAnnouncement />
          </template>
          <!--            行业动态-->
          <template v-else-if="showData.key === 'industryDynamics'">
            <IndustryDynamics />
          </template>
          <!--            媒体报道-->
          <template v-else-if="showData.key === 'mediaReport'">
            <MediaReport />
          </template>
          <!--          视频资料-->
          <template v-else-if="showData.key === 'videoData'">
            <VideoData />
          </template>
          <!--          文件资料-->
          <template v-else-if="showData.key === 'documentData'">
            <DocumentData />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../index.scss";

.news {
  display: flex;
}
.other_news {
  width: 100%;
}
</style>
