var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Banner',{attrs:{"bannerSrc":require('@/assets/banner/newsCenter.jpg'),"tabs":_vm.tabsOption,"selTab":_vm.currentTab.key}}),_c('div',{staticClass:"padding-comm-warp _content_warp news"},[(
        [
          'corporateCulture',
          'organizationalStructure',
          'aptitude',
          'intro'
        ].includes(_vm.showData.key)
      )?[_c('div',{staticClass:"left"},[_c('LeftMenu',{attrs:{"currentTab":_vm.currentTab,"childSelect":_vm.childSelect}})],1),_c('div',{staticClass:"right"},[_c('Breadcrumb',{attrs:{"breadcrumbList":_vm.breadcrumbOption}}),_c('div',{staticClass:"content"},[(_vm.showData.key === 'corporateCulture')?_c('div',[_c('CorporateCulture')],1):(_vm.showData.key === 'organizationalStructure')?_c('div',[_c('OrganizationalStructure')],1):(_vm.showData.key === 'aptitude')?_c('div',[_c('Aptitude')],1):_c('div',[_c('notData')],1)])],1)]:_c('div',{staticClass:"other_news"},[_c('Breadcrumb',{attrs:{"breadcrumbList":_vm.breadcrumbOption}}),_c('div',{staticClass:"content"},[(_vm.showData.key === 'news')?[_c('GroupNews')]:_vm._e(),(_vm.showData.key === 'newsInfo')?[_c('GroupNewsInfo',{attrs:{"id":_vm.showData.id}})]:(_vm.showData.key === 'announcement')?[_c('PublicAnnouncement')]:(_vm.showData.key === 'industryDynamics')?[_c('IndustryDynamics')]:(_vm.showData.key === 'mediaReport')?[_c('MediaReport')]:(_vm.showData.key === 'videoData')?[_c('VideoData')]:(_vm.showData.key === 'documentData')?[_c('DocumentData')]:_vm._e()],2)],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }